body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RoobertMedium"; /*Can be any text*/
  src: local("RoobertMedium"),
    url("./fonts/Roobert-Medium.woff2") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: "RoobertRegular"; /*Can be any text*/
  src: local("RoobertRegular"),
    url("./fonts/Roobert-Regular.woff2") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: "RoobertLight"; /*Can be any text*/
  src: local("RoobertLight"), url("./fonts/Roobert-Light.woff2") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: "ObviouslyDemoRegular"; /*Can be any text*/
  src: local("ObviouslyDemoRegular"),
    url("./fonts/ObviouslyDemo-Regu.otf") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: "ObviouslyDemoWide"; /*Can be any text*/
  src: local("ObviouslyDemoWide"),
    url("./fonts/ObviouslyDemo-Wide.otf") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: "ObviouslyDemoWideMedium"; /*Can be any text*/
  src: local("ObviouslyDemoWideMedium"),
    url("./fonts/ObviouslyDemo-WideMedi.otf") format("woff2");
  font-display: auto;
  unicode-range: U+00-7F;
}
