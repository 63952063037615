.RoobertRegularBody {
  font-family: "RoobertRegular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
}

.RoobertMediumBody {
  font-family: "RoobertMedium";
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 57.6px */
  color: black;
}
