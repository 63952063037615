.pink {
  background: linear-gradient(
    0deg,
    rgba(219, 52, 172, 0.49) 0%,
    rgba(219, 52, 172, 0.49) 100%
  );
}

.yellow {
  background: linear-gradient(
      0deg,
      rgba(236, 240, 40, 0.49) 0%,
      rgba(236, 240, 40, 0.49) 100%
    ),
    lightgray 50% / cover no-repeat;
  background-blend-mode: color, normal;
}

.blue {
  background: linear-gradient(
    0deg,
    rgba(0, 106, 124, 0.49) 0%,
    rgba(0, 106, 124, 0.49) 100%
  );
}

.green {
  background: linear-gradient(
    0deg,
    rgba(0, 202, 137, 0.49) 0%,
    rgba(0, 202, 137, 0.49) 100%
  );
  background-color: #00ca897d;
}

.red-pink {
  background: linear-gradient(
    0deg,
    rgba(219, 52, 52, 0.49) 0%,
    rgba(219, 52, 52, 0.49) 100%
  );
}

.violet {
  background: linear-gradient(
    0deg,
    rgba(36, 0, 255, 0.49) 0%,
    rgba(36, 0, 255, 0.49) 100%
  );
}

.sky-blue {
  background: linear-gradient(0deg, #252ad2 0%, #252ad2 100%);
}

.orange {
  background: linear-gradient(
    0deg,
    rgba(238, 118, 0, 0.49) 0%,
    rgba(238, 118, 0, 0.49) 100%
  );
}
