.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .line-background {
  background: url("../public/images/common/lines.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;

  background-color: #e7e7e7;
} */

/* .line-background-slat {
  background: url("../public/images/common/lines.webp");
  background-size: cover;
  background-repeat: repeat;
  background-position-y: center;

  background-color: #e7e7e7;
} */

/* Your custom styles */
.carousel-container {
  width: 100%;
  /* margin: 50px auto; */
}
/* 
.quote-slide {
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 200px;
} */
/* Arrow Symbol */
.slick-prev,
.slick-next {
  display: none;
}

/* Update the positioning of the custom arrows within the slide card */
/* .slide-card {
  position: relative;
} */

.custom-arrow {
  position: absolute;

  transform: translateY(-50%);
  /* Add styles for arrow icon and other custom styling */
}

.custom-prev-arrow {
  top: 0px;
  right: 40px; /* Adjust this value to position the arrow */
}

.custom-next-arrow {
  top: 0px;
  right: 20px; /* Adjust this value to position the arrow */
}

.story-card-background {
  background: url("../public/images/story/card-background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
}

.RoobertRegular {
  font-family: "RoobertRegular";
}

.RoobertMedium {
  font-family: "RoobertMedium";
}

.RoobertLight {
  font-family: "RoobertLight";
}

.ObviouslyDemoRegular {
  font-family: "ObviouslyDemoRegular";
}

.ObviouslyDemoWideMedium {
  font-family: "ObviouslyDemoWideMedium";
}

.strategy-small-card-style {
  border-radius: 5.985px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    225deg,
    rgba(21, 22, 23, 0.7) 0%,
    rgba(51, 55, 66, 0.5) 100%
  );
  box-shadow: 0px 17.956249237060547px 11.970832824707031px -11.970832824707031px
    rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(14.963541030883789px);
}

.scroll-testimonials img {
  display: inline-block;
  max-width: initial;
  margin-right: 30px;
  height: 50px;
}

.band-1 {
  width: 25880px;
  transform: translateX(0%);
  animation: move 250s linear infinite;
  padding-bottom: 10px;
}

.band-2 {
  width: 15820px;
  transform: translateX(0%);
  animation: move 150s linear infinite;
  animation-delay: 0s;
}

@keyframes move {
  to {
    transform: translateX(-150%);
  }
}

/* #dropdown {
  transition: opacity 0.3s ease-in-out;
} */

/* Show the dropdown when hovering over the button */
/* .button:hover #dropdown {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  
} */

/* .button:hover #dropdown {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
} */

.customer-background {
  background: url("../public/images/customer-page/hero.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
}

.light-red {
  background-color: #ffeded;
}

.light-green {
  background-color: #f5ffea;
}

.light-blue {
  background-color: #cae5fd;
}

.light-violet {
  background-color: #f3eaff;
}

.green {
  background-color: #c0f4cc;
}

.light-orange {
  background-color: #ffeaea;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 37, 39, 0.7);
  z-index: 30; /* Higher z-index to cover other elements */
}

html {
  scroll-behavior: smooth;
}

.dropdown-card {
  border-radius: 12px;
  background: #fdfdfd;
  box-shadow: 0px 2.27273px 3.78788px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(15.151522636413574px);
}

.modal-example {
  height: 100vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-modal {
  position: fixed;
  z-index: 1040;
  display: flex;
  left: 20%;
  right: 20%;
  top: 10%;
  width: 60%;
  height: 80%;
  /* align-items: center;
  justify-content: center; */
  width: fit-content;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1300px) {
  .custom-modal {
    left: 7%;
    right: 5%;
    top: 10%;
    width: 87%;
    height: 80%;
  }
}

@media (max-width: 385px) {
  .responsive-width {
    width: 94%;
  }
}

/* Add this CSS for modal content */

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.video-container {
  position: relative;
  width: 100%;
  max-height: 100vh;

  overflow: hidden;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: #fff;
  opacity: 0.8;
  filter: blur(67.5px);
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  aspect-ratio: 3/2;
}

.content {
  position: relative;
  z-index: 2; /* Content appears above the video */
  /* height: 880px; */
  max-height: 100vh;
  /* Add styling for your content here */
}

.vertical-carousel2 {
  width: 100%; /* Set your desired width */
  height: 400px; /* Set your desired height */
  overflow-y: scroll; /* Allow vertical scrolling within the carousel */
  position: relative; /* Set the position to relative */
  scrollbar-width: none; /* Hide the scrollbar on Firefox */
  -ms-overflow-style: none; /* Hide the scrollbar on IE/Edge */
}

.vertical-carousel2::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar on Chrome/Safari/Opera */
}

.carousel-item2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px; /* Adjust slide height as needed */
  border: 1px solid #ccc; /* Add borders or styles */
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.marquee-content {
  display: flex;
  animation: scrolling 10s linear infinite;
}

.marquee-item {
  /* flex: 0 0 16vw;
  margin: 0 1vw; */
  /*   flex: 0 0 20vw; */
  /*   margin: 0 2vw; */
}

.marquee-item img {
  display: block;
  width: 100%;
  /*   padding: 0 20px; */
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-144vw);
  }
}

/* .slider {
  height: 60px;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
} */
/* .slider::before,
.slider::after {
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
} */

/*  IMPORTANT CODE BELOW */

.slide-track {
  width: calc(100% * 20);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}

.slide {
  width: 100%;
  height: 60px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(80px * 20);
  }

  .slide {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 10));
    }
  }
}

.about-mobile {
  border-radius: 5.985px;
  border: 0.748px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    217deg,
    rgba(40, 43, 52, 0.7) 0%,
    rgba(51, 55, 66, 0.5) 100%
  );
  box-shadow: 0px 17.95625px 11.97083px -11.97083px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(14.963541030883789px);
}

.clip-card {
  border-radius: 14px;
  border: 0.748px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    217deg,
    rgba(40, 43, 52, 0.7) 0%,
    rgba(51, 55, 66, 0.5) 100%
  );
  box-shadow: 0px 17.95625px 11.97083px -11.97083px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(14.963541030883789px);
}

.swiper-pagination-vertical {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 10px 20px;
  padding-right: 34px;
}

.swiper-bullet-vertical {
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: inline-block;
  border-radius: 50%;
  margin: 0.5rem 0rem;
}

.swiper-bullet-active-vertical {
  background: black;
  opacity: 1;
}
